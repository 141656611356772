@font-face {
  font-family: "Noto Sans KR";
  src: url("../font/NotoSansKR-Regular.otf"), url("../font/NotoSansKR-Bold.otf");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url("../font/NotoSansKR-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Regular.ttf"), url("../font/Roboto-Bold.ttf");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
